import * as React from "react";
import { Navbar } from "../components/Navbar";
import { SEO } from "../components/SEOComponent";
import { ACTIVITIES, ActivityIDS } from "../../static/data/activities";
import { ALL_COUNTRIES } from "../../static/data/countries";
import { DisplayCard } from "../components/DisplayCard";
import { TempFormDataContext } from "../context/temp-form-data";
import { ArrowRight } from "react-feather";
import { Link } from "gatsby";
import { WrapPageDefault, WrapPageNoHeight } from "../components/WrapPageDefault";
import ClientActivityMessage, { ClientCountryActivities, instanceOfClientActivityMessage } from "shared-models/build/client/activities";
import { sendGenericPostRequest } from "../util/util";
import Footer from "../components/Footer";
import { CheckoutCart } from "../components/CheckoutCart";

interface Props {
    pageContext: {
        country: string;
        description: string;
        activities: ClientActivityMessage[],
        countryId: number;
    }
};

const Country: React.FC<Props> = ({ pageContext }) => {
    const { formData, setFormData } = React.useContext(TempFormDataContext);
    const [activities, setActivities] = React.useState<ClientActivityMessage[]>([]);

    React.useEffect(() => {
        getAdditionalActivities();
    }, []);  

    React.useEffect(() => {
        if (pageContext.activities) {
            setActivities(pageContext.activities);
        }
    }, [pageContext]);

    // Get more activities if updating in real time
    const getAdditionalActivities = async () => {
        let existingIds = pageContext.activities?.reduce((obj : { [key: number]: boolean }, act) => {
            if (!(instanceOfClientActivityMessage)) return obj;
            return {
                ...obj,
                [act.ActivityId]: true,
            }
        }, []);

        if (!existingIds) {
            existingIds = [];
        }

        const [response, err] = await sendGenericPostRequest("/activity/get-verified-activities", {}, "", "");
        if (err !== null) {
            console.log(err);
            return;
        }
        const countryAct : ClientCountryActivities = response?.find((val: ClientCountryActivities) => val.CountryId === pageContext.countryId);
        if (!countryAct) return;

        setActivities(countryAct.Activity);

        if (err !== null) {
            console.log(response, err);
        }
    }


    
    const addActivity = (activity: ClientActivityMessage) => {
        const selection = formData.Selection;

        // Add to the Selection
        if (!(pageContext.country in selection)) {
            selection[pageContext.country] = {};
        }

        if (activity.ActivityId in selection[pageContext.country]) {
            delete selection[pageContext.country][activity.ActivityId];
        } else {
            selection[pageContext.country] = {
                ...selection[pageContext.country],
                [activity.ActivityId]: activity
            }
        }

        setFormData({
            ...formData,
            Selection: selection,
        })
    }

    return (
        <>
        <WrapPageNoHeight>
            <SEO
                Title={`CloudSafari | Discover your next adventure in ${pageContext.country}`}
                Description={pageContext.description}
            />
            <Navbar />
        </WrapPageNoHeight>

        {/* Line */}
        <div className="tw-h-[1px] tw-bg-gray-200 tw-shadow"></div>
        
        <WrapPageDefault>
        <div>
            {/* Activities */}
            <div className="tw-flex tw-flex-wrap tw-pt-12 tw-items-start tw-min-h-screens">
                {
                    activities?.map((act : ClientActivityMessage ) => {
                        if (!instanceOfClientActivityMessage(act)) return null;
                        const activitySelected = formData.Selection?.[pageContext.country]?.[act.ActivityId];
                        return (
                            <DisplayCard 
                                Title={`${activitySelected ? "✅ " : "" } ${act.Name}`}
                                SubTitle=""
                                Description={act.Description}
                                ImageURL={act.ImageURL}
                                Clickable={true}
                                onClick={() => addActivity(act)}
                                link=""
                                extraClasses="sm:tw-pr-4 md:tw-pr-8 tw-pb-8"
                                extraImageClasses={`tw-transition hover:tw-shadow-lg tw-cursor-pointer ${ activitySelected ? "tw-opacity-40" : "" }`}
                            />
                        )
                    })
                }
                {
                    activities?.length === 0 ?
                    <h3 className="tw-text-lg">
                        Activities coming soon!
                    </h3>
                    :
                    null
                }
            </div>
            
            <CheckoutCart />
        </div>
        </WrapPageDefault>
        <Footer />
        </>
    )
};

export default Country;